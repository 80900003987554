// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery3
//= require popper
// require bootstrap-sprockets
//= require bootstrap
//= require fontawesome/all
//= require bootstrap-datepicker
//= require select2
//= require jquery_ujs
//= require jquery-ui/widgets/autocomplete
//= require autocomplete-rails
// require jquery-lazy

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("turbolinks").start();

// jquery
import "jquery";
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// bootstrap
import * as bootstrap from "bootstrap";
window.bootstrap = require('bootstrap');

// Jquery-ui for sortable
import "jquery-ui";
require("jquery-ui/ui/widget");
require("jquery-ui/ui/widgets/datepicker");
//require("jquery-ui/ui/widgets/sortable");

//import { betrrFunctions } from '../custom/betrr_functions';

document.addEventListener('turbolinks:load', () => {
//    betrrFunctions();
    function loadImage(elem) {
        let url = $(elem).attr("data-src");
        let newImg = new Image();
        newImg.onload = function () {
            console.log("done loading");
            $(elem).attr("src", url);
        }
        newImg.src = url;
    }
    let imgs = $("img.lazy");
    for(let a=0;a<imgs.length;a++){
        loadImage(imgs[a]);
    }
    document.addEventListener('render_async_load', function(event) {
        let imgs = $("img.lazy");
        for (let a = 0; a < imgs.length; a++) {
            loadImage(imgs[a]);
        }
    });

    // Display matches for competitions and teams
    $(".btn-filter-option").on('click',function(){
        $('.btn-filter-option').removeClass('btn-filter-active');
        $(this).addClass('btn-filter-active');
    });

    // Display odds on matches
    $("#yes-no-odds").on('click',function(){
        if ($('#yes-no-odds').is(':checked')) {
            $('.match-odds').css('display','flex');
        } else {
            $('.match-odds').css('display','none');
        }
    });

    $("#yes-no-live").on('click',function(){
        if ($('#yes-no-live').is(':checked')) {
            $('.match-not-live-match').css('display','none');
            $('.match-list-in-competition').css('display','none');
            $('.match-list-in-competition').has('.match-live-match').css('display','block');
            $('.match-list-in-grouping').css('display','none');
            $('.match-list-in-grouping').has('.match-live-match').css('display','block');
            if ($('.match-list-in-grouping:visible').length == 0)
              $('.no-live-matches-available').css('display','block');
        } else {
            $('.match-not-live-match').css('display','flex');
            $('.match-list-in-competition').css('display','block');
            $('.match-list-in-grouping').css('display','block');
            $('.no-live-matches-available').css('display','none');
        }
    });

    // Close menu and calendar if click anywhere else
    $(window).click(function() {
        $('.header-menu-anchor').show();
        $('.header-menu-close').hide();
        $('.header-menu-content').hide();
        $('.sidebar').removeClass('sidebar-as-header-menu');
        $('#datepicker').hide();
    });

    // Toggle main menu
    $(document).ready(function(){
        $('.header-menu-anchor, .header-menu-close').click(function(event){
            $('.header-menu-anchor').toggle();
            $('.header-menu-close').toggle();
            $('.header-menu-content').toggle();
            event.stopPropagation();
        });
    });

    // Toggle mobile menu
    $(document).ready(function(){
        $('.header-menu-anchor-mobile, .header-menu-close-mobile').click(function(){
            $('.header-menu-anchor-mobile').toggle();
            $('.header-menu-close-mobile').toggle();
            if ($('.sidebar').hasClass('sidebar-as-header-menu'))
                $('.sidebar').removeClass('sidebar-as-header-menu');
            else
                $('.sidebar').addClass('sidebar-as-header-menu');
            event.stopPropagation();
        });
    });

    // Toggle mobile search
    $(document).ready(function(){
        $('.header-search-mobile-switch').click(function(){
            $('.header-search-form').toggle();
        });
    });

    // Disable pin actions as soon as clicked to prevent multiple pins for fast clickers
    $(document).on('ajax:beforeSend', 'a.color-pins[data-remote=true]', function(event, xhr, status, error) {
      $(this).prop("href", "#");
    });

});